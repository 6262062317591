<template>
  <v-row class="text-center" v-if="!mini">
    <v-col v-if="showDays">
      <div class="text-h2 font-weight-bold" :class="{'etext': days === 0 && hours === 0 && minutes === 0 && seconds < 6}">{{ days | twoDigits }}</div>
      <div class="caption">Days</div>
    </v-col>
    <v-col v-if="showHours">
      <div class="text-h2 font-weight-bold" :class="{'etext': days === 0 && hours === 0 && minutes === 0 && seconds < 6}">{{ hours | twoDigits }}</div>
      <div class="caption">Hours</div>
    </v-col>
    <v-col :class="{'text-right': !showDays && !showHours}">
      <div class="text-h2 font-weight-bold" :class="{'etext': days === 0 && hours === 0 && minutes === 0 && seconds < 6}">{{ minutes | twoDigits }}</div>
      <div class="caption">Minutes</div>
    </v-col>
    <v-col :class="{'text-left': !showDays && !showHours}">
      <div class="text-h2 font-weight-bold" :class="{'etext': days === 0 && hours === 0 && minutes === 0 && seconds < 6}">{{ seconds | twoDigits }}</div>
      <div class="caption">Seconds</div>
    </v-col>
  </v-row>
  <span v-else>
    <template v-if="showDays">
      {{ days | twoDigits }}:
    </template>
    <template v-if="showHours">
      {{ hours | twoDigits }}:
    </template>
    {{ minutes | twoDigits }}:
    {{ seconds | twoDigits }}
  </span>
</template>

<script>
export default {
  props: ['date', 'showDays', 'showHours', 'startMe', 'lengthInSeconds', 'mini', 'emitOnZero', 'cycle', 'expiry'],
  data () {
    return {
      now: null,
      endDate: null,
      interval: null
    }
  },
  computed: {
    dateInMilliseconds () {
      return this.endDate && Math.trunc(Date.parse(this.endDate) / 1000)
    },
    seconds () {
      return this.endDate && (this.dateInMilliseconds - this.now) % 60
    },
    minutes () {
      return this.endDate && Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60
    },
    hours () {
      return this.endDate && Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24
    },
    days () {
      return this.endDate && Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24)
    },
    expired () {
      return this.dateInMilliseconds - this.now < 0
    }
  },
  methods: {
    onStart (v) {
      if (this.interval) clearInterval(this.interval)
      if (!v) return
      const t = this.expiry ? new Date(this.expiry) : new Date()
      // const x = t.getSeconds() + this.lengthInSeconds
      // console.log(x)
      t.setSeconds(t.getSeconds() + this.lengthInSeconds)
      this.endDate = t
      this.now = Math.trunc((new Date()).getTime() / 1000)
      this.interval = setInterval(() => {
        this.now = Math.trunc((new Date()).getTime() / 1000)
        if (this.expired) {
          this.$emit('expired')
        }
      }, 1000)
    }
  },
  watch: {
    startMe: function (v) {
      this.onStart(v)
    },
    expired: function (v) {
      this.$emit('expired')
      if (this.cycle) {
        this.onStart(true)
      }
    }
  },
  mounted () {
    this.onStart(true)
  },
  destroyed () {
    this.onStart(false)
  }
}
</script>

<style scoped>
.digit {
  font-size: x-large;
}
.etext {
  color: #ff0000 !important;
}
</style>
